<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <strong>Daftar tagihan</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <div class="row">
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                  <label>Tanggal: </label>
                  <a-date-picker format="DD-MM-YYYY" v-model="filter" class="ml-2" @change="getAllData"/>
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 ml-2">
                  <label>Kode AO: </label>
                  <a-input class="ml-2" v-model="filterao" placeholder="Input Kode AO" @change="filterbyao"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- <a-table
          :columns="columndefs"
          :dataSource="datadefs"
          size="small"
          style="margin-left: -25px; margin-right: -25px; margin-top: -25px;"
          :pagination="{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        ></a-table> -->
        <ag-grid-vue
          :style="settings.isMobileView ? 'height: 400px;' : 'height: 50vh;'"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          :rowClassRules="rowClassRules"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :columnDefs="columnDefs"
          :rowData="datadefs"
        ></ag-grid-vue>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created () {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    // setTimeout(() => {
    this.getAllData()
    // }, 300)
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      columnDefs: [
        {
          headerName: 'ao',
          field: 'ao',
          resizable: true,
          filter: true,
          sortable: true,
        },
        {
          headerName: 'Rekening',
          field: 'rekening',
          resizable: true,
          filter: true,
          pinned: 'left',
        },
        {
          headerName: 'Nama',
          field: 'nama',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'Alamat',
          field: 'alamat',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'Kecamatan',
          field: 'kecamatan',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'Telp',
          field: 'telp',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'Tgl',
          field: 'tgl',
          resizable: true,
          filter: true,
          valueFormatter: this.dateFormatter,
        },
        {
          headerName: 'Plafond',
          field: 'plafond',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'Lama',
          field: 'lama',
          width: 70,
          resizable: true,
          filter: true,
        },
        {
          headerName: 'Ke',
          field: 'ke',
          width: 50,
          resizable: true,
          filter: true,
        },
        {
          headerName: 'BakiDebet',
          field: 'bakidebet',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'SaldoPokok',
          field: 'saldopokok',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'Kolek',
          field: 'kolek',
          width: 50,
          resizable: true,
          filter: true,
        },
        {
          headerName: 'CicilanPokok',
          field: 'cicilanpokok',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'CicilanMarkup',
          field: 'cicilanmarkup',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'TotalCicilan',
          field: 'totalcicilan',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'KewajibanPokok',
          field: 'kewajibanpokok',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'KewajibanMarkup',
          field: 'kewajibanmarkup',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'PelunasanPokok',
          field: 'pelunasanpokok',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'PelunasanMarkup',
          field: 'pelunasanmarkup',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'TunggakanPokok',
          field: 'tunggakanpokok',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'TunggakanMarkup',
          field: 'tunggakanmarkup',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'TotalTunggakan',
          field: 'totaltunggakan',
          resizable: true,
          filter: true,
          valueFormatter: this.currencyFormatter,
          sortable: true,
          type: 'rightAligned',
        },
        {
          headerName: 'FrekuensiPokok',
          field: 'frekuensipokok',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'FrekuensiMarkup',
          field: 'frekuensimarkup',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'HariTunggakan',
          field: 'haritunggakan',
          resizable: true,
          filter: true,
        },
        {
          headerName: 'JatuhTempo',
          field: 'jatuhtempo',
          resizable: true,
          filter: true,
          valueFormatter: this.dateFormatter,
        },
      ],
      columndefs: [
        {
          title: 'ke',
          dataIndex: 'ke',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'ke' },
        },
        {
          title: 'rekening',
          dataIndex: 'rekening',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'rekening' },
        },
        {
          title: 'nama',
          dataIndex: 'nama',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'nama' },
        },
        {
          title: 'tgl',
          dataIndex: 'tgl',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'alamat',
          dataIndex: 'alamat',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'alamat' },
        },
        {
          title: 'kecamatan',
          dataIndex: 'kecamatan',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'kecamatan' },
        },
        {
          title: 'telp',
          dataIndex: 'telp',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'telp' },
        },
        {
          title: 'ao',
          dataIndex: 'ao',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'ao' },
        },
        {
          title: 'bakidebet',
          dataIndex: 'bakidebet',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'bakidebet' },
        },
        {
          title: 'cicilanmarkup',
          dataIndex: 'cicilanmarkup',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'cicilanmarkup' },
        },
        {
          title: 'cicilanpokok',
          dataIndex: 'cicilanpokok',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'cicilanpokok' },
        },
        {
          title: 'frekuensimarkup',
          dataIndex: 'frekuensimarkup',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'frekuensimarkup' },
        },
        {
          title: 'frekuensipokok',
          dataIndex: 'frekuensipokok',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'frekuensipokok' },
        },
        {
          title: 'haritunggakan',
          dataIndex: 'haritunggakan',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'haritunggakan' },
        },
        {
          title: 'jatuhtempo',
          dataIndex: 'jatuhtempo',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'jatuhtempo' },
        },
        {
          title: 'kewajibanmarkup',
          dataIndex: 'kewajibanmarkup',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'kewajibanmarkup' },
        },
        {
          title: 'kewajibanpokok',
          dataIndex: 'kewajibanpokok',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'kewajibanpokok' },
        },
        {
          title: 'kolek',
          dataIndex: 'kolek',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'kolek' },
        },
        {
          title: 'lama',
          dataIndex: 'lama',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'lama' },
        },
        {
          title: 'pelunasanmarkup',
          dataIndex: 'pelunasanmarkup',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'pelunasanmarkup' },
        },
        {
          title: 'pelunasanpokok',
          dataIndex: 'pelunasanpokok',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'pelunasanpokok' },
        },
        {
          title: 'plafond',
          dataIndex: 'plafond',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'plafond' },
        },
        {
          title: 'saldopokok',
          dataIndex: 'saldopokok',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'saldopokok' },
        },
        {
          title: 'totalcicilan',
          dataIndex: 'totalcicilan',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'totalcicilan' },
        },
        {
          title: 'totaltunggakan',
          dataIndex: 'totaltunggakan',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'totaltunggakan' },
        },
        {
          title: 'tunggakanmarkup',
          dataIndex: 'tunggakanmarkup',
          // className: 'text-right text-gray-6',
          scopedSlots: { customRender: 'tunggakanmarkup' },
        },
      ],
      datadefs: null,
      backupdatadefs: null,
      filter: moment(),
      filterao: '',
    }
  },
  methods: {
    moment,
    async getAllData() {
      // console.log('this.filter', this.filter.format('YYYY-MM-DD'))
      var res = await lou.customUrlGet('report/daftartagihan?periode=' + this.filter.format('YYYY-MM-DD'))
      if (res) {
        this.datadefs = res.data
        this.backupdatadefs = res.data
      }
    },
    filterbyao() {
      var result = this.filterao === '' ? this.backupdatadefs : this.backupdatadefs.filter((x) => x.ao.includes(this.filterao))
      console.log('result', result)
      this.datadefs = result
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    dateFormatter(params) {
      var tgl = params.value === null ? '' : params.value.slice(0, 10)
      var ret = tgl !== '' ? moment(tgl, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
      // console.log('params.value', params.value)
      return ret
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style></style>
